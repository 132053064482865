.button {
	display: inline-block;
	padding: $paddingDefault*.71 $paddingDefault*2;
	font-weight: bold;
	text-align: center;
	cursor: pointer;
	transition: color $transitionTimeFast, backgorund-color $transitionTimeFast;
	min-width: 170px;
	background-color: $c-emerald;
	color: #fff;
	border-radius: 30px;
	text-decoration: none;
	border: 2px solid $c-emerald;

	&:hover {
		background-color: transparent;
		color: $c-emerald;
	}

	.dark & {
		color: $c-emerald;
		background-color: #fff;
		border-color: #fff;

		&:hover {
			background-color: transparent;
			color: #fff;
		}
	}
}

.button--error {
	background-color: $c-error;
	color: #fff;
	border: 2px solid $c-error;

	&:hover {
		color: $c-error;
	}

	.dark & {
		color: $c-error;
		background-color: #fff;
		border-color: #fff;

		&:hover {
			background-color: transparent;
			color: #fff;
		}
	}
}

.button-holder {
	text-align: center;
	max-width: 300px;
	margin-left: auto;
	margin-right: auto;

	.button {
		display: block;
		margin: $marginDefault*.5;
	}
}