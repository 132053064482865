// ------- TYPOGRAPHIC STYLES
body {
	@include fontsize($fs-default);
	font-family: $ff-default;
}

a {
	color: currentColor;
}

em {
	font-style: italic;
}

strong {
	font-weight: bold;
}

h1, .fauxH1,
h2, .fauxH2,
h3, .fauxH3,
h4, .fauxH4,
h5, .fauxH5,
h6, .fauxH6 {
	font-family: $ff-secondary;
	font-weight: 600;

	.light &,
	.block--light & {
		color: $c-mineShaft;
	}

	.dark &,
	.block--dark & {
		color: #fff;
	}
}

h1, .fauxH1 {
	@include fontsize($fs-h1Small);
	@include query_min($bp-sm) {
		@include fontsize($fs-h1);
	}
	line-height: $lh-h1;
}
h2, .fauxH2 {
	@include fontsize($fs-h2Small);
	@include query_min($bp-sm) {
		@include fontsize($fs-h2);
	}
	line-height: $lh-h2;
}
h3, .fauxH3 {
	@include fontsize($fs-h3);
	line-height: $lh-h3;
	font-weight: 700;
}
h4, .fauxH4 {
	@include fontsize($fs-h4);
}
h5, .fauxH5 {
	@include fontsize($fs-h5);
}
h6, .fauxH6 {
	@include fontsize($fs-h6);
	color: $c-silverChalice;
	font-weight: 700;
}

.text--left {
	text-align: left;
}

.text--right {
	text-align: right;
}

.text--center {
	text-align: center;
}