.block-pricing {
	.col {
		margin: $marginDefault 0;
	}
}

.pricing-banner {
	@include query_min($bp-md){
		padding-left: $paddingDefault;
		padding-right: $paddingDefault;
	}
}

.pricing-bannerInner {
	padding: $paddingDefault*1.5 0;
	border: 1px solid $c-concrete;
	background-color: #fff;
}

.pricing-plan {
	padding: 0 $paddingDefault*1.5;
}

.pricing-planName {
	padding-top: $paddingDefault;

	h2 {
		@include fontsize($fs-pricingPlanName);
		color: $c-emperor;
		font-weight: 700;
	}
}

.pricing-planCost {
	@include fontsize($fs-pricingPlanPrice);
	color: $c-mineShaft;
	font-weight: 800;
}

.pricing-planSub {
	color: $c-silverChalice;
	text-transform: uppercase;
	font-weight: 600;
	margin-top: $marginDefault*.1;
}

.pricing-plan + .pricing-content {
	margin-top: $marginDefault*1.5;
	border-top: 1px dashed $c-mercury;
}

.pricing-content {
	padding: $paddingDefault*1.5 $paddingDefault*1.5 $paddingDefault;
}

.pricing-cta {
	padding: 0 $paddingDefault*1.5 $paddingDefault;

	select {
		width: auto;
		margin: $marginDefault*.75 auto $marginDefault !important;
	}

	label + input {
		width: auto;
		margin: $marginDefault*.75 auto $marginDefault !important;
	}
}