.newsletterForm-successMessage {
	margin-top: 0;
	display: none;
}

.newsletterForm--success {
	form {
		display: none;
	}

	.newsletterForm-successMessage {
		display: block;
	}
}