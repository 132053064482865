@mixin query_min($bp, $device: 'screen'){
	@media only #{$device} and (min-width: $bp)
	{
		@content;
	}
}

@mixin query_max($bp, $device: 'screen'){
	@media only #{$device} and (max-width: $bp)
	{
		@content;
	}
}

@mixin query_min_max($bp_min, $bp_max, $device: 'screen'){
	@media only #{$device} and (min-width: $bp_min) and (max-width: $bp_max)
	{
		@content;
	}
}

@mixin query_min_height($bp, $device: 'screen'){
	@media only #{$device} and (min-height: $bp)
	{
		@content;
	}
}

@mixin query_max_height($bp, $device: 'screen'){
	@media only #{$device} and (max-height: $bp)
	{
		@content;
	}
}

@mixin query_min_max_height($bp_min, $bp_max, $device: 'screen'){
	@media only #{$device} and (min-height: $bp_min) and (max-height: $bp_max)
	{
		@content;
	}
}