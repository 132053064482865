.social {
	a {
		display: inline-block;
		width: 24px;
		height: 24px;
		background-color: $c-mineShaft;
		color: #fff;
		border-radius: 50%;
		text-decoration: none;
		text-align: center;
		line-height: 1;
		vertical-align: middle;
		transition: color $transitionTimeFast, background-color $transitionTimeFast;
		position: relative;

		&:before {
			font-size: 14px;
			outline: 1px solid transparent;
			position: absolute;
			width: 100%;
			height: 100%;
			text-align: center !important;
			left: 0;
			top: 5px;
		}
	}

	a + a {
		margin: 0 0 0 $marginDefault*.5;
	}
}

.social--small {
	a {
		width: 17px;
		height: 17px;

		&:before {
			font-size: 11px;
			top: 3px;
		}

		&:hover {
			color: $c-emerald;
		}
	}
}

.social--inverted {
	a {
		background-color: #fff;
		color: $c-mineShaft;

		&:hover {
			color: $c-emerald;
		}
	}
}

.social--color {
	.icon-youngliving {
		background-color: $c-youngliving;
	}
	.icon-twitter {
		background-color: $c-twitter;
	}
	.icon-facebook {
		background-color: $c-facebook;
	}
	.icon-pinterest {
		background-color: $c-pinterest;
	}
	.icon-instagram {
		background-color: $c-instagram;
	}
	.icon-web {
		background-color: $c-web;
	}
}