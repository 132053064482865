main {
	ul, ol {
		padding-left: $paddingDefault;
		text-align: left;

		li + li {
			margin-top: 0 !important;
		}
	}

	ul li {
		list-style-type: disc;
	}

	ol li {
		list-style-type: decimal;
	}
}