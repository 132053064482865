@charset "UTF-8";

// Default Variables
$slick-loader-path: "./" !default;
$slick-dot-color: #000;
$slick-dot-color-active:  #000;
$slick-dot-character: '\2022';
$slick-dot-size: 8px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @return url($slick-loader-path + $url);
}

/* Slider */
.slick-list {
    .slick-loading & {
        background: #fff url("/assets/images/ajax-loader.gif") center center no-repeat;
    }
}

/* Arrows */
.slick-prev,
.slick-next {
    position: absolute;
    display: none;
    height: $fs-arrowHeight;
    width: $fs-arrowWidth;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    margin-top: $fs-arrowHeight * -.5;
    padding: 0;
    border: none;
    outline: none;
    z-index: 1000;
    text-align: center;

    &:hover, &:focus {
        outline: none;
        background: transparent;
        color: transparent;
        &:before {
            opacity: $slick-opacity-on-hover;
            transition: opacity $transitionTime linear, color $transitionTime linear;
	        color: $c-arrowsHover;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }

    &:before {
        font-family: $ff-icons;
        @include fontsize($fs-arrowHeight);
        line-height: 1;
        color: $c-arrows;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 100%;
        text-align: center;
        display: block;
        position: absolute;
        z-index: 2;
	    top: 0;
    }
}

.slick-prev {
    left: $arrowOffset;
    [dir="rtl"] & {
        left: auto;
        right: $arrowOffset;
    }
    &:before {
        content: '<';
        [dir="rtl"] & {
            content: '>';
        }
    }
}

.slick-next {
    right: $arrowOffset;
    [dir="rtl"] & {
        left: $arrowOffset;
        right: auto;
    }
    &:before {
        content: '>';
        [dir="rtl"] & {
            content: '<';
        }
    }
}

/* Dots */
.slick-dots {
    position: absolute;
    bottom: - $marginDefault*1.6;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0;
            font-size: 0;
            color: transparent;
            padding: 4px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: $slick-dot-character;
                width: 20px;
                height: 20px;
                font-family: $ff-icons;
                font-size: $slick-dot-size;
                line-height: 20px;
                text-align: center;
                color: $slick-dot-color;
                transition: color $transitionTime linear;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active button:before {
            color: $slick-dot-color-active;
        }
    }
}