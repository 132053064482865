//---------- Top Bar ----------
.topBar {
	padding-top: $paddingDefault*.7;
	padding-bottom: $paddingDefault*.7;
	line-height: 1;
	display: none;
}

.info {
	display: inline-block;
	vertical-align: middle;
	@include fontsize($fs-topBarInfo);
	font-weight: 600;
	cursor: default;


	+ .info {
		margin-left: $marginDefault*2;
	}

	span {
		@include fontsize($fs-topBarIcons);
		display: inline-block;
		vertical-align: middle;
		margin-right: $marginDefault*.5;
	}
}
a.info {
	text-decoration: none;
	cursor: pointer;
}

//---------- Main Menu ----------
.mainMenu {
	padding: $paddingDefault*1.5 0;
	transition: padding $transitionTimeFast, top $transitionTimeFast;
	background-color: rgba(#fff, 1);
	position: static;
	top: -60px;

	&.floating-nav {
		padding: $paddingDefault*.5 0;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1000;
		background-color: $c-mineShaft4;
		color: #fff;
	}

	@include query_max($bp-md-px - 1px) {
		.mobileMenuOpen & {
			z-index: 1000;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			overflow-y: auto;
			overflow-x: hidden;
		}
	}
}
.floating-menu-spacer {
	width: 100%;
}

.mainMenu-logo {

}

.logo {
	@include fontsize(0);
	img, span {
		vertical-align: middle;
	}

	span {
		display: inline-block;
		margin-left: $marginDefault*.75;
		line-height: 1.2;
		font-weight: 600;
		@include fontsize($fs-default);
	}

	&:hover {
		span {
			color: $c-emerald;
		}
	}
}

.logo-on-light {
	display: inline-block;
}
.logo-on-dark {
	display: none;
}

.floating-nav {
	.logo-on-light {
		display: none;
	}

	.logo-on-dark {
		display: inline-block;
	}
}


.logo-image {
	width: 45px;
}

.logo-text {
	width: calc(100% - 45px - #{$marginDefault});
}

.mainMenu-nav {

}

.mainNav {
	ul {
		margin: 0;
		padding: 0;
	}

	li {
		list-style-type: none;
		display: inline-block;
	}

	li + li {
		margin-left: $marginDefault;
	}

	a {
		text-decoration: none;
		@include fontsize($fs-nav);
		line-height: $lh-nav;
		font-weight: 600;

		&:hover, &.selected {
			color: $c-emerald;
		}

		&.button {
			&:hover, &.selected {
				background-color: #fff;
				color: $c-emerald;
			}
		}
	}
}

//---------- mobile menu ----------
.mobileMenu {
	display: none;

	li {
		display: block;
		text-align: center;
	}

	a {
		text-decoration: none;
		@include fontsize($fs-navMobile);
	}

	@include query_max($bp-md-px - 1px) {
		.mobileMenuOpen & {
			display: block;
			padding: $paddingDefault*1;
			margin-top: $marginDefault*2;
		}
	}
}

//---------- mobile menu toggle ----------
.mobileMenu-toggle {
	float: right;
	position: relative;
	top: 3px;
	left: -15px;
	background-color: #fff;
	cursor: pointer;
	padding: 10px 35px 16px 0;

	/*&:before {
		@include fontsize(40px);
		position: relative;
		top: -6px;
		outline: 1px solid transparent;
	}*/

	span, span:before, span:after {
		cursor: pointer;
		height: 2px;
		width: 35px;
		position: absolute;
		display: block;
		content: '';
		transition: all $transitionTimeFast ease-in-out;
		background-color: $c-doveGray;
	}

	span {
		&:before {
			top: -9px;
		}

		&:after {
			bottom: -9px;
		}

		.mobileMenuOpen & {
			background-color: transparent !important;

			&:before, &:after {
				top: 0;
			}

			&:before {
				transform: rotate(35deg);
			}

			&:after {
				transform: rotate(-35deg);
			}
		}
	}

	span span {
		display: none;
	}

	.floating-nav & {
		background-color: $c-mineShaft4;

		span, span:before, span:after {
			background-color: #fff;
		}
	}
}