@include query_max($bp-md-px - 1px) {
	html {
		overflow: visible;
		&.mobileMenuOpen {
			overflow: hidden;
		}
	}

	body {
		overflow-y: scroll;
		.mobileMenuOpen {
			overflow-y: hidden;
		}
	}
}

body {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

.header {

}

.main {
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;

	> * {
		width: 100%;
	}

	&.full {
		min-height: 100%;
		justify-content: center;
		text-align: center;
	}
}

.block-cpEditLink {
	margin-top: auto;

	.full & {
		margin-top: 0;
	}
}

.footer {
	margin-top: auto;
}