.qna-holder {
	text-align: left;

	+ .qna-holder {
		margin-top: $marginDefault*2;
	}
}

.qna-question {

}

.qna-answer {

}