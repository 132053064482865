/* https://css-tricks.com/snippets/sass/strip-unit-function/
* Removes the unit of a length
* @param {Number} $number - Number to remove unit from
* @return {Number} - Unitless number
*/
@function strip-unit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}
	@return $number;
}


@function rems($size) {
	@if type-of($size) == 'number' and not unitless($size) and unit($size) == 'rem' {
		@return $size;
	}
	$size: strip-unit( $size );
	@return $size / 16 * 1rem;
}

@mixin fontsize($size) {
	$size: strip-unit( $size );
	font-size: $size * 1px;
	font-size: rems($size);
}
