.is-countdown {

}
.countdown-holding span {
	color: #888;
}
.countdown-row {
	@extend .row;
	@extend .center-xs;
}

.countdown-section {
	@extend .col;
	@extend .col-xs;
	margin-top: 0;
}

.countdown-amount {
	display: block;
	@include fontsize($fs-countdownAmountXs);
	line-height: 1;
	font-weight: 700;
	font-family: $ff-secondary;
	color: $c-emerald;

	@include query_min($bp-sm) {
		@include fontsize($fs-countdownAmountSm);
	}

	@include query_min($bp-md) {
		@include fontsize($fs-countdownAmountMd);
	}

	@include query_min($bp-lg) {
		@include fontsize($fs-countdownAmountLg);
	}
}
.countdown-period {
	display: block;
	margin-top: $marginDefault;
	line-height: 1;
	@include fontsize($fs-countdownPeriodXs);
	font-weight: 600;
	color: $c-emperor;
	text-transform: uppercase;

	@include query_min($bp-sm) {
		@include fontsize($fs-countdownPeriodSm);
		margin-top: $marginDefault*1.5;
	}
}