//---------- slides ----------
.slides-full {
	position: absolute !important;
	height: 100%;
	width: 100%;
	z-index: 1;

	.slick-track, .slick-list {
		height: 100% !important;
	}

	.slide {
		display: block;
		height: 100% !important;
		width: 100%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		object-fit: cover;
	}

	.slide--anchor-left {
		background-position: left center;
	}
}