.block-masthead {
	position: relative;

	.row {
		min-height: 385px;
		padding: $paddingDefault*3 0;
	}

	.heading + .text-content {
		margin-top: $marginDefault*.9;
	}

	.slides {
		z-index: 1;
	}

	.wrapper {
		z-index: 2;
		color: #fff;
	}

	&.has-bg {
		.slides {
			@include opacity(.3);
		}
	}

	&.no-bg {
		.wrapper {
			text-shadow: 0 0 8px rgba(#000,.3);
		}
	}
}