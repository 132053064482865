//---------- Bottom Bar ----------
.bottomBar {
	padding: $paddingDefault*.75 0;
	line-height: 1;

	.col {
		padding-top: $paddingDefault*.35;
		padding-bottom: $paddingDefault*.35;
		@include query_max($bp-sm) {
			text-align: center;
		}
	}

}