.block-padded {
	padding-top: $paddingDefault;
	padding-bottom: $paddingDefault;
}

.block-padded--extra {
	padding-top: $paddingDefault*2;
	padding-bottom: $paddingDefault*2;
}

.block-padded--more {
	padding-top: $paddingDefault*3;
	padding-bottom: $paddingDefault*3;
}

.block-padded--large {
	padding-top: $paddingDefault*3.5;
	padding-bottom: $paddingDefault*3.5;
}

.block-padded--huge {
	padding-top: $paddingDefault*4;
	padding-bottom: $paddingDefault*4;
}

.main {
	border-top: 1px solid $c-concrete;

	.bg-white + .bg-white {
		padding-top: 0;
		margin-top: -3em;
	}

	.bg-alabaster + .bg-alabaster {
		padding-top: 0;
		margin-top: -3em;
	}

	.bg-white + .bg-alabaster,
	.bg-alabaster + .bg-white,
	.borderedTop {
		border-top: 1px solid $c-concrete;
	}
}