$gutter-compensation: $gutter-width * .5 * -1;

.wrapper {
	box-sizing: border-box;
	max-width: $flexboxgrid-max-width;
	margin: 0 auto;
}

.container-fluid {
	margin-right: auto;
	margin-left: auto;
	padding-right: $outer-margin*.5;
	padding-left: $outer-margin*.5;
}

.row {
	box-sizing: border-box;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 0;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: $gutter-compensation;
	margin-left: $gutter-compensation;
}

.row.reverse {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

.col.reverse {
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	-ms-flex-direction: column-reverse;
	flex-direction: column-reverse;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
	box-sizing: border-box;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	padding-right: $gutter-width-half;
	padding-left: $gutter-width-half;
}

$name: xs;
.col-#{$name} {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	max-width: 100%;
}
@for $i from 1 through $grid-columns {
	.col-#{$name}-#{$i} {
		-ms-flex-preferred-size: (100% / $grid-columns * $i);
		flex-basis: (100% / $grid-columns * $i);
		max-width: 100% / $grid-columns * $i;
	}
}
@for $i from 1 through $grid-columns {
	.col-#{$name}-offset-#{$i} {
		margin-left: 100% / $grid-columns * $i;
	}
}
/*.col-#{$name} {
	@include flex-grow(1);
	@include flex-basis(0);
	max-width: 100%;
}*/
.start-#{$name} {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	text-align: start;
}

.center-#{$name} {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
}

.end-#{$name} {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	text-align: end;
}

.top-#{$name} {
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.middle-#{$name} {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.bottom-#{$name} {
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.around-#{$name} {
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.between-#{$name} {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.first-#{$name} {
	-webkit-box-ordinal-group: 0;
	-ms-flex-order: -1;
	order: -1;
}

.last-#{$name} {
	-webkit-box-ordinal-group: 2;
	-ms-flex-order: 1;
	order: 1;
}

.hidden-#{$name} {
	display: none;
}

.top-margin-none-#{$name} {
	margin-top: 0 !important;
}

.top-margin-default-#{$name} {
	margin-top: $marginDefault !important;
}


@each $name, $size in $breakpoints {
	@media only screen and (min-width: $size) {
		.container {
			width: $size - $outer-margin;
		}

		@for $i from 1 through $grid-columns {
			.col-#{$name}-#{$i} {
				-ms-flex-preferred-size: 100% / $grid-columns * $i;
				flex-basis: 100% / $grid-columns * $i;
				max-width: 100% / $grid-columns * $i;
			}
		}
		@for $i from 1 through $grid-columns {
			.col-#{$name}-offset-#{$i} {
				margin-left: 100% / $grid-columns * $i
			}
		}
		.col-#{$name} {
			-webkit-box-flex: 1;
			-ms-flex-positive: 1;
			flex-grow: 1;
			-ms-flex-preferred-size: 0;
			flex-basis: 0;
			max-width: 100%;
		}
		.start-#{$name} {
			-webkit-box-pack: start;
			-ms-flex-pack: start;
			justify-content: flex-start;
			text-align: start;
		}

		.center-#{$name} {
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			text-align: center;
		}

		.end-#{$name} {
			-webkit-box-pack: end;
			-ms-flex-pack: end;
			justify-content: flex-end;
			text-align: end;
		}

		.top-#{$name} {
			-webkit-box-align: start;
			-ms-flex-align: start;
			align-items: flex-start;
		}

		.middle-#{$name} {
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
		}

		.bottom-#{$name} {
			-webkit-box-align: end;
			-ms-flex-align: end;
			align-items: flex-end;
		}

		.around-#{$name} {
			-ms-flex-pack: distribute;
			justify-content: space-around;
		}

		.between-#{$name} {
			-webkit-box-pack: justify;
			-ms-flex-pack: justify;
			justify-content: space-between;
		}

		.first-#{$name} {
			-webkit-box-ordinal-group: 0;
			-ms-flex-order: -1;
			order: -1;
		}

		.last-#{$name} {
			-webkit-box-ordinal-group: 2;
			-ms-flex-order: 1;
			order: 1;
		}

		.hidden-#{$name} {
			display: none;
		}

		.block-#{$name} {
			display: block !important;
		}

		.inline-#{$name} {
			display: inline-block !important;
		}

		.top-margin-none-#{$name} {
			margin-top: 0 !important;
		}

		.top-margin-default-#{$name} {
			margin-top: $marginDefault !important;
		}
	}
}