//---------- Colors ----------
$c-mineShaft: #303030;
$c-mineShaft2: #2a2a2a;
$c-mineShaft3: #202020;
$c-mineShaft4: #323232;
$c-heavyMetal: #2a2b2a;
$c-gray: #808080;
$c-silverChalice: #ababab;
$c-alabaster: #fafafa;
$c-gallery: #ebebeb;
$c-emerald: #4fbf70;
$c-concrete: #f2f2f2;
$c-emperor: #545454;
$c-mercury: #e5e5e5;
$c-doveGray: #6a6a6a;
$c-wildSand: #f6f6f6;
$c-dustyGray: #969696;
$c-funBlue: #1859a9;
$c-goldenGrass: #e1ac28;

//text color
$c-text: $c-gray;

//background color
$c-bodyBg: $c-alabaster;

//selection background color
$c-selection: darken($c-emerald,.05%);

//forms
$c-error: red;

//social icons
$c-twitter: #55acee;
$c-facebook: #3b5998;
$c-pinterest: #cb2027;
$c-instagram: #125688;
$c-youngliving: #fabe2f;
$c-web: $c-gray;

//---------- Background Colors ----------
$arr-backgroundsDark: (
	emerald: $c-emerald,
	funBlue: $c-funBlue,
	goldenGrass: $c-goldenGrass,
	mineShaft: $c-mineShaft,
	mineShaft2: $c-mineShaft2,
	mineShaft3: $c-mineShaft3,
	heavyMetal: $c-heavyMetal,
	emperor: $c-emperor
);
@each $name, $color in $arr-backgroundsDark {
	.bg-#{$name} {
		background-color: $color;
		color: #fff;
	}
}
$arr-backgroundsLight: (
	white: #fff,
	alabaster: $c-alabaster
);
@each $name, $color in $arr-backgroundsLight {
	.bg-#{$name} {
		background-color: $color;
		color: $c-text;
	}
}

//---------- Spacing ----------
$marginDefaultPx: 16px;
$marginDefault: rems($marginDefaultPx);
$paddingDefaultPx: 16px;
$paddingDefault: rems($paddingDefaultPx);

//---------- Timing ----------
$transitionTime: .5s; //update in site.js if changed
$transitionTimeFast: .25s; //update in site.js if changed

//---------- Typography ----------
$ff-default: 'Open Sans', sans-serif;
$ff-secondary: 'Josefin Sans', sans-serif;

//font sizes
$fs-default: 16px;
$lh-default: 1.73;

//nav
$fs-nav: 14px;
$lh-nav: 1;
$fs-navMobile: 16px;
$lh-navMobile: 1;

//headings
$fs-h1: 60px;
$fs-h1Small: 30px;
$lh-h1: 1.2;
$fs-h2: 48px;
$fs-h2Small: 25px;
$lh-h2: 1.1;
$fs-h3: 26px;
$lh-h3: 1.2;
$fs-h4: 23px;
$fs-h5: 20px;
$fs-h6: 16px;

//pricing block
$fs-pricingPlanName: 34px;
$fs-pricingPlanPrice: 33px;

//topBar
$fs-topBarInfo: 11px;
$fs-topBarIcons: 16px;

//form
$fs-formLabels: $fs-default;
$lh-formLabels: 1.1;
$fs-formInputs: 14px;
$lh-formInputs: 1.2;

$fs-countdownAmountXs: 30px;
$fs-countdownAmountSm: 48px;
$fs-countdownAmountMd: 76px;
$fs-countdownAmountLg: 90px;
$fs-countdownPeriodXs: 12px;
$fs-countdownPeriodSm: 16px;

//---------- Breakpoints ----------
$bp-sm-px: 768px;
$bp-md-px: 1024px;
$bp-lg-px: 1200px;
$bp-sm: rems($bp-sm-px);
$bp-md: rems($bp-md-px);
$bp-lg: rems($bp-lg-px);

//---------- Flexbox Grid ----------
$grid-columns: 12;
$gutter-width: 1rem;
$gutter-width-half: $gutter-width * .5;
$gutter-width-double: $gutter-width * 2;
$outer-margin: $gutter-width-double;
$breakpoints: (
	sm: $bp-sm,
	md: $bp-md,
	lg: $bp-lg
);

$flexboxgrid-max-width: 1200px;

//---------- Slider/Lightbox Arrows ----------
$arrowOffset: 0;
$fs-arrowHeight: 35px;
$fs-arrowWidth: 18px;
$c-arrows: #fff;
$c-arrowsLightbox: #fff;
$c-arrowsLightboxHover: #fff;
$c-arrowsHover: lighten(#fff, 25%);

//---------- Font Icons ----------
$ff-icons: 'icomoon';
$iconFontPath: "/assets/fonts";
$iconHash: 'w2xue2';

$icon-calendar: "\e900";
$icon-clock: "\e901";
$icon-facebook: "\e902";
$icon-instagram: "\e903";
$icon-linkedin: "\e904";
$icon-marker: "\e905";
$icon-mobile: "\e906";
$icon-pinterest: "\e907";
$icon-twitter: "\e908";
$icon-web: "\e909";
$icon-youngliving: "\e90a";
